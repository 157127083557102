
import React from "react";
import LinkedInLogo from "../assets/LinkedInLogo.png";
import MailLogo from "../assets/MailLogo.png";
import WhatsLogo from "../assets/WhatsLogo.png";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <section className="social-section">
          <div className="social-links">
            <a
              href="https://www.linkedin.com/company/js-technologies1/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedInLogo} alt="LinkedIn Logo" />
            </a>
            <a
              href="mailto:junaidanwar4676@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={MailLogo} alt="Mail Logo" />
            </a>
            <a
              href="https://wa.me/+923494050691"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-link"
            >
              <img src={WhatsLogo} alt="WhatsApp Logo" />
            </a>
          </div>
          <p>© 2024 All Rights Reserved | <b>JS Tech</b></p>
        </section>
      </div>
    </div>
  );
}

export default Footer;
