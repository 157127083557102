import React from "react";
import "./Service.css";
import Flutter from "../assets/Flutter.png";
import android2 from "../assets/android2.png";
import ios from "../assets/ios.png";
import servpage from "../assets/servpage.jpg";
import webscrapslider from "../assets/webscrapslider.png";
import AIServ from "../assets/AIServ.png";

function Service() {
  return (
    <section className="serv-page">
      <div className="ser-container">
        <div className="sercontain">
          <img src={servpage} />
        </div>

        <div className="serv-sec">
          <h1 className="head-serv">Services</h1>
          <p>
            Our comprehensive suite of services helps your business grow online.
            Can not decide what your project needs? Discuss your project with
            our specialists, and we will help you grow.
            <br />
            <br />
            <b>We are experts in all Digital Services</b>
          </p>
          <div className="slist">
            <ul>
              <li>Flutter and Native Apps</li>
              <li>Website Development</li>
              <li>Software Development</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Android App Development */}
      <div className="and-container">
        <div className="and-sec">
          <h3 className="head-and">Android Development</h3>
          <p>
            <b>JS Technologies</b> offers comprehensive, platform-agnostic solutions
            with a focus on Android app development. We follow an agile approach
            to deliver intuitive, high-quality apps that achieve significant
            downloads on the Google Play Store known for their dedication,
            professionalism, and innovative thinking.
            <span className="extra-response">
            <br />
            <b><b>JS Technologies</b></b> has a strong portfolio of successful projects, making
            them a top choice for app development services.
            </span>
          </p>
        </div>
        <div className="andcontain">
          <img src={android2} alt="Android Development" />
        </div>
      </div>

      {/* iOS Development Section */}
      <div className="ios-container">
        <div className="ioscontain">
          <img src={ios} />
        </div>
        <div className="ios-sec">
          <h3 className="ios-head">iOS Development</h3>
          <p>
            As iOS operating system is witnessing technological advancement with
            every passing day, we build user friendly and interactive ios apps
            and work hardly to make it appealing to your target audience and
            performance oriented for end-users. 
            <span className="extra-response">Starting point of any app is to
            identify the design and define choices and features and to implement
            those choices in a correct order is necessary. Our developers are
            well aware of iOS, X code iOS simulator and latest features of ios
            and UI kit framework.</span>
          </p>
        </div>
      </div>

      {/* Flutter App Development Section */}
      <div className="flut-container">
        <div className="flut-sec">
          <h3 className="head-flut">Flutter App Development</h3>
          <p>
            Flutter, a cross-functional platform using a single codebase,
            provides the best opportunity to build the next generation of
            applications. Flutter frameworks have endless possibilities, as well
            as fast and simple development.{" "}
            <span className="extra-response">
              <b><b>JS Technologies</b></b> provides its best expertise in Flutter app
              development services, including interactive UI designs, flawless
              animation, and smooth performance. We understand the needs of our
              clients and work closely with them to develop the best digital
              solution, exceeding expectations and making every project a huge
              success.
            </span>
          </p>
        </div>
        <div className="flutcontain">
          <img src={Flutter} alt="Flutter Development" />
        </div>
      </div>

      {/* Web Scrapping Section */}
      <div className="scrap-container">
        <div className="scrapcontain">
          <img src={webscrapslider} />
        </div>
        <div className="scrap-sec">
          <h3 className="scrap-head">Web Scraping</h3>
          <p>
            At <b><b>JS Technologies</b></b>, we offer advanced Web Scraping Services that help
            businesses extract valuable data from websites in real-time. Our
            tailored solutions enable you to gather market insights, monitor
            competitors, and automate data collection processes efficiently.
            <span className="extra-response">
              Whether you need product prices, customer reviews, or industry
              trends, our web scraping tools ensure accurate, structured data at
              scale. With robust technology and strict compliance measures, we
              deliver secure and reliable data extraction, empowering your
              business with actionable insights.
            </span>
          </p>
        </div>
      </div>

      {/* AI Section */}
      <div className="AI-container">
        <div className="AI-sec">
          <h3 className="AI-head">AI-Powered Solutions for Your Business</h3>
          <p>
            At <b><b>JS Technologies</b></b>, we offer cutting-edge AI services designed to
            transform your business operations. From machine learning models
            that optimize decision-making to intelligent automation tools that
            streamline processes, our AI solutions help drive innovation and
            efficiency.{" "}
            <span className="extra-response">
              Whether it's predictive analytics, natural language processing, or
              custom AI applications, we provide tailored services that empower
              businesses to stay ahead in the digital era.
            </span>
          </p>
        </div>
        <div className="AIcontain">
          <img src={AIServ} />
        </div>
      </div>
    </section>
  );
}

export default Service;
