import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./components/Home";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./Hero";
import Service from "./components/Service";
import Contacts from "./components/Contacts";
import About from "./components/About";

const slides = [
  {
    image: require("./assets/WebDevSlider4.png"),
    title: "Web Development",
    subtitle:
      "Creating Engaging, Responsive Websites with Modern Technologies to Enhance User Experience and Business Growth",
    ctaText: "Book a Free Consultation",
    ctaLink: "/contacts",
  },
  {
    image: require("./assets/AiSlider.png"),
    title: "Chat Bot AI",
    subtitle:
      "Empowering Businesses with Intelligent, Conversational AI for Seamless Customer Interactions and Support",
    ctaText: "Book a Free Consultation",
    ctaLink: "/contacts",
  },
  {
    image: require("./assets/mobDevSlider.png"),
    title: "Mobile Development",
    subtitle:
      "Developing Innovative Mobile Applications for Seamless User Experiences Across All Devices and Platforms",
    ctaText: "Book a Free Consultation",
    ctaLink: "/contacts",
  },
  {
    image: require("./assets/webscrapslider1.png"),
    title: "Web Scrapping",
    subtitle:
      "Websites Automated Data Extraction from Websites for Efficient Analysis, Market Research, and Competitive Intelligence",
    ctaText: "Book a Free Consultation",
    ctaLink: "/contacts",
  },
];

const MainApp = () => {
  const location = useLocation();

  return (
    <div className="App">
      <NavBar />
      {/* Show Hero section only on the Home page */}
      {location.pathname === "/" && <Hero slides={slides} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
      <Footer />
    </div>
  );
};

function AppWithRouter() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

export default AppWithRouter;
