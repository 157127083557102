import React, { useState } from "react";
import "./Contacts.css";
import Contact from "../assets/Contact.png"; // Ensure the correct path is used for the image
import mailcont from "../assets/mailcont.png";
import phone from "../assets/phone.png";
function Contacts() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    setSubmitted(true);
  };

  return (
    <div className="contacts-container">
      <div className="heading-container">
        <h1 className="form-heading">Contact Us</h1>
        <img
          src={Contact}
          className="heading-illustration illustration-fade-in-up"
          alt="Contact Illustration"
        />
      </div>

      <section className="form-container">
        {submitted ? (
          <h3 className="thank-you-message">THANK YOU! for Your Request!</h3>
        ) : (
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="wrapper">
              <div className="f-logo">
                <img
                  src={Contact}
                  alt="JS Technologies Logo"
                />
              </div>
              <br />
              <br />
              <div
                className="text-center mt-4 name"
                style={{ marginLeft: "11%" }}
              >
                Request Form
                <p className="sub">
                  Fill Out Our Form & We’ll be in Touch Shortly
                </p>
              </div>
              <br />
              <div className="grid-container">
                <div className="form-field">
                  <span className="far fa-user"></span>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name*"
                    required
                  />
                </div>

                <div className="form-field">
                  <span className="far fa-user"></span>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name*"
                    required
                  />
                </div>

                <div className="form-field">
                  <span className="far fa-envelope"></span>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    required
                  />
                </div>

                <div className="form-field">
                  <span className="fas fa-phone"></span>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number*"
                    pattern="{11}"
                    title="Phone Number*"
                    required
                  />
                </div>

                <div className="form-field">
                  <span className="far fa-user"></span>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    placeholder="Company Name*"
                  />
                </div>

                <div className="form-field">
                  <span className="far fa-user"></span>
                  <input
                    type="text"
                    name="hear"
                    id="hear"
                    placeholder="How did you hear ?*"
                    required
                  />
                </div>

                <div className="seclast-field">
                  <span className="fas fa-comment"></span>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Project Details*"
                    required
                  ></textarea>
                </div>

                <div className="last-field">
                  <span className="fas fa-laptop-code"></span>
                  <select name="developmentType" id="developmentType" required>
                    <option value="Development Type*">
                      Select Development Type
                    </option>
                    <option value="web">Web Development</option>
                    <option value="app">App Development</option>
                  </select>
                </div>
              </div>

              <button className="btn mt-3" type="submit">
                Submit
              </button>
              <br />
            </div>
          </form>
        )}
      </section>
      <section className="contact-info-section">
        <b>Contact Information</b>
        <br />
        If you have any query, please Email with us any questions:
        <br />
        <img className="mail" src={mailcont} />
        <a href="mailto:junaidanwar4676@gmail.com">junaidanwar4676@gmail.com</a>
        <br />
        <br />
        Or Contact with us:
        <br />
        <img className="mail" src={phone} />
        <a href="https://wa.me/+923494050691">+92 3494050691</a>
      </section>
    </div>
  );
}

export default Contacts;
