import React from "react";
import "./About.css";
import Aboutsec from "../assets/Aboutsec.jpg";

function About() {
  return (
    <div>
      <section className="about-page">
        <div className="about-container">
          <div className="abtcontain">
            <img src={Aboutsec}  alt="About IMG"/>
          </div>
          <div className="abt-secn">
            <h3 className="abtpage">About Us !</h3>
            <p>
              Your Trusted Partner in Innovation and Excellence <br />
              At JS Technologies, we pride ourselves on delivering top-notch solutions
              with a track record of over 5 years. Our journey has been marked
              by serving a diverse range of clients and providing solutions
              tailored to meet their unique needs. Our commitment to innovation
              and excellence has established us as a reliable partner in the
              tech industry. We are dedicated to not only meeting but exceeding
              expectations, and our growing list of satisfied clients stands
              testament to our dedication. We have built a reputation for
              quality, reliability, and customer satisfaction. Our clients trust
              us to drive their success, and we are proud to have earned their
              confidence through our exceptional service.
            </p>
          </div>
        </div>
      </section>

      <section className="terms">
        <h3>Terms and Conditions</h3>

        <p>
          By accessing or using our website and services, you agree to comply
          with and be bound by the following terms and conditions. <br />
          Please review the following carefully. If you do not agree with these
          terms, you must not use our services. <br />
          <br />
          <ol>
            <li>
              {" "}
              <b> Services</b>
              <br />
              JS Technologies provides software development, web design, mobile app
              development, and related consulting services. The scope of
              services will be defined in specific agreements made between JS
              Tech and its clients.
            </li>
            <br />
            <li>
              {" "}
              <b> User Responsibilities</b>
              <br /> You agree to use our services only for lawful purposes. You
              must not interfere with the operation of our website or services,
              including introducing malware, viruses, or harmful code. You are
              responsible for maintaining the confidentiality of any login
              credentials or sensitive information shared with you in relation
              to our services.
            </li>
            <br />
            <li>
              {" "}
              <b> Intellectual Property</b> <br />
              All materials, content, and services provided by JS Technologies,
              including software, website design, and proprietary code, are
              protected by copyright and intellectual property laws. You may not
              duplicate, reproduce, or distribute any of the content without
              express written permission.
            </li>
            <br />
            <li>
              <b> Third-Party Links </b>
              <br />
              Our website may contain links to third-party websites or services
              that are not owned or controlled by JS Technologies. We are not
              responsible for the content or practices of third-party websites
              and encourage you to review their terms and policies.
            </li>
            <br />
            <li>
              <b> Payment and Fees </b>
              <br />
              All services provided by JS Technologies are subject to payment as per the
              terms specified in our agreements or contracts. Failure to make
              payments on time may result in suspension or termination of
              services.
              <br />
            </li>
            <br />
            <li>
              <b>Confidentiality</b> <br />
              Both parties agree to keep confidential all information shared
              during the course of the engagement, unless expressly authorized
              to share it publicly or required by law.
              <br />
            </li>
            <br />
            <li>
              <b>Termination</b> <br />
              Either party may terminate the agreement for services by providing
              written notice. Upon termination, all outstanding payments for
              services rendered must be settled within 15 days.
              <br />
            </li>
            <br />
            <li>
              <b>Disclaimer of Warranties</b> <br />
              JS Technologies makes no warranties, either express or implied, about the
              performance or quality of its services, except as specifically
              outlined in service agreements.
              <br />
            </li>
            <br />
            <li>
              <b> Limitation of Liability</b> <br />
              In no event shall JS Technologies be liable for any indirect, incidental,
              special, or consequential damages arising out of the use of our
              services, even if advised of the possibility of such damages.
              <br />
            </li>
            <br />
            <li>
              <b>Changes to Terms</b> <br />
              We reserve the right to modify or replace these Terms and
              Conditions at any time. Continued use of our services following
              any changes constitutes acceptance of those changes.
              <br />
            </li>
            <br />
            <li>
              <b>Contact Information</b> <br />
              If you have any questions about these Terms and Conditions, please
              contact us at: <br />
              <br />
              Email:
              <a href="mailto:junaidanwar4676@gmail.com">
                junaidanwar4676@gmail.com
              </a>
              <br />
              Linked In:
              <a href="https://www.linkedin.com/company/js-technologies1/">
                JS Technologies
              </a>
            </li>
          </ol>
        </p>
      </section>
    </div>
  );
}

export default About;
