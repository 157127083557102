import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import './Hero.css'; // Your custom CSS

function hero({ slides }) {
  return (
    <div className="hero-container" >
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        navigation
        modules={[Navigation, Pagination, Autoplay]}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              <div className="slide-text">
                <h1>{slide.title}</h1>
                
                 <p> {slide.subtitle}</p>
                <a href={slide.ctaLink} className="cta-button">
                  {slide.ctaText}
                </a>
                </div>

                <div
                className="slide-image"
                style={{ backgroundImage: `url(${slide.image})` }}
              ></div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
export default hero;
