import React from "react";
import "./Home.css";
import Aboutill from "../assets/Aboutill.png";
import Mission from "../assets/Mission.jpg";
import AppDev from "../assets/AppDev.png";
import WebDev from "../assets/WebDev.png";
import GraphDes from "../assets/GraphDes.png";
import owner from "../assets/owner.png";

const ServiceCard = ({ title, description, imageUrl }) => (
  <div className="service-card">
    <div className="header-container">
      <div className="image-container">
        <img src={imageUrl} alt={title} className="service-image" />
      </div>
      <h2>{title}</h2>
    </div>
    <p>{description}</p>{" "}
    <a href="./Service"  className="view-packages-btn">
      How we do ? →
    </a>
  </div>
);

function Home() {
  return (
    <div className="homepage">
      <section className="sabout">
        <div className="heading-container">
          <div className="about-text">
            <h3 className="habout">About Us!</h3>
            <p>
              Welcome to <b>JS Technologies!</b> <br />
              Your trusted partner in innovation and excellence.
            </p>
            <a href="./About" className="about-btn">
              About Us
            </a>
          </div>
          <div className="imgcontainer1">
            <img src={Aboutill} alt="About Section"/>
          </div>
        </div>
      </section>

      <section className="sowner">
        <div className="heading-container">
          <div className="imgcontainer1">
            <img src={owner} alt="About Owner"/>
          </div>
          <div className="owner-text">
            <h3 className="howner"> Founder's Words</h3>
            <p>
              Hi!
              <br /> I'm <b>Junaid Anwar</b>, a passionate IT Professional
              dedicated to crafting innovative Software, Web, and Applications
              solutions that bring your ideas to life. With a focus on client
              satisfaction, I deliver tailored experiences that exceed
              expectations. <br />
              <br />
              Let's! collaborate and turn your vision into a digital reality.
            </p>
            <br /> <br />
          </div>
        </div>
      </section>

      <section className="smission">
        <div className="heading-container">
          <div className="mission-text">
            <h3 className="hmission">Mission</h3>
            <p className="responsive-paragraph">
              Our software development company <b>JS Technologies</b> is driven
              by a mission to design and develop innovative solutions that
              transform businesses and improve lives. Guided by values of
              collaboration, innovation, customer-centricity, quality, and
              integrity, we leverage our expertise in custom software
              development, agile methodologies, and cutting-edge technologies to
              deliver exceptional results.{" "}
              <span className="extra-t4ext">
                With a strong focus on user experience and expertise in data
                analytics, AI, and machine learning, we prioritize client
                satisfaction and success in everything we do.
              </span>
            </p>
          </div>
          <div className="imgcontainer1">
            <img src={Mission} alt="mission img"/>
          </div>
        </div>
      </section>

      <section className="home-services">
        <div className="home-services-content">
          <h2 className="homeservices-heading" style={{ marginBottom: '0px' }}>Services</h2>
        </div>
        <div className="services-section">
          <ServiceCard
            title="WEBSITE DEVELOPMENT"
            description="The most creative websites are built by us. Just tell us what you have in mind and leave the development to us."
            imageUrl={WebDev}
          />
          <ServiceCard
            title="MOBILE APP DEVELOPMENT"
            description="Need a mobile app developed? We develop mobile apps for IOS and Android Operating System."
            imageUrl={AppDev}
          />
          <ServiceCard
            title="WEB SCRAPPING"
            description="You want to scrap your data? We offer advanced Web Scraping Services that helps in business."
            imageUrl={GraphDes}
          />
          <ServiceCard
            title="AI POWERED SOLUTIONS"
            description="You want to add AI Technology in your business? See our AI Services to enhance your business"
            imageUrl={WebDev}
          />
        </div>
      </section>
    </div>
  );
}

export default Home;
