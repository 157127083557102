import React, { useState } from "react";
import "./NavBar.css";
import JsTechLogo from "../assets/JsTechLogo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? "open" : ""}`}>
      <div className="logo">
        <a href="/" rel="noopener noreferrer">
          <img src={JsTechLogo} />
        </a>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        &#9776; {/* Hamburger menu icon */}
      </div>
      <div className={`navbar-items ${isOpen ? "open" : ""}`}>
        <a href="/">Home</a>
        <a href="about">About</a>
        <a href="service">Service</a>
        <a href="contacts">Contacts</a>
      </div>
    </nav>
  );
}

export default Navbar;
